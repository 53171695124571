import React from "react";
import { Card } from "antd";

const CustomCard = ({ title, icon, className }) => {
  return (
    <Card className={className}>
      <div className="custom-content flex flex-col items-start gap-2">
        <div className="custom-icon bg-[#022555] py-2 px-3 rounded-md text-[24px] text-white">{icon}</div>
        <div className="custom-title font-Roboto text-[20px] font-semibold text-[#022555] uppercase">{title}</div>
      </div>
    </Card>
  );
};

export default CustomCard;