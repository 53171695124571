import React, { useState, useEffect } from "react";
import {
  HomeOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
  AppstoreAddOutlined,
  MoneyCollectOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Button, Typography, Dropdown } from "antd";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FloatButton } from "antd";
import { useNavigate } from "react-router-dom";
import imageSrc from "./micro1.png";
import collapsedImageSrc from "./micro12.png";
import { PiCross } from "react-icons/pi";
import { RiMenu2Line } from "react-icons/ri";
import { RiMenu3Line } from "react-icons/ri";
import { HiOutlineUsers } from "react-icons/hi2";

const { Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;

// const loggedInUserType = localStorage.getItem("loggedInUserType");



const adminUserItems = [
  {
    key: "dashboard",
    icon: <HomeOutlined />,
    label: "Dashboard",
  },
  {
    key: "expenses",
    icon: <MoneyCollectOutlined />,
    label: "Expenses",
    children: [
      {
        key: "expenses",
        icon: <AppstoreAddOutlined />,
        label: "Expenses",
      },
    ],
  },
  {
    key: "funds",
    icon: <MoneyCollectOutlined />,
    label: "Funds",
    children: [
      {
        key: "fund",
        icon: <AppstoreAddOutlined />,
        label: "Funds",
      },
    ],
  },
  {
    key: "users",
    icon: <HiOutlineUsers />,
    label: "Users",
    children: [
      {
        key: "users",
        icon: <AiOutlineUserAdd />,
        label: "Users",
      },
    ],
  },
];

const UserFundItems = [
  {
    key: "dashboard",
    icon: <HomeOutlined />,
    label: "Dashboard",
  },
  {
    key: "funds",
    icon: <MoneyCollectOutlined />,
    label: "Funds",
    children: [
      {
        key: "fund",
        icon: <AppstoreAddOutlined />,
        label: "Funds",
      },
    ],
  },
];
const UserExpencesItems = [
  {
    key: "dashboard",
    icon: <HomeOutlined />,
    label: "Dashboard",
  },
  {
    key: "expenses",
    icon: <MoneyCollectOutlined />,
    label: "Expenses",
    children: [
      {
        key: "expenses",
        icon: <AppstoreAddOutlined />,
        label: "Expenses",
      },
    ],
  },
];

// const headerIteam = [
//   // { key: "1", text: `User: ${loggedInUserType}`, icon: <UserSwitchOutlined /> },
//   { key: "2", text: "LogOut", icon: <LogoutOutlined /> },
// ];


const App = ({ children, userType }) => {
  const navigate = useNavigate();
  // console.log("usertype:" + loggedInUserType);

  const [collapsed, setCollapsed] = useState(false);
  const [loggedInUserType, setLoggedInUserType] = useState("");
  



  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth <= 768);
    };

    const userType = localStorage.getItem("loggedInUserType");
    if (userType) {
      setLoggedInUserType(userType);
    }

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    
  }, []);

  console.log("usertype:" + loggedInUserType);

  const handleHeaderClick = (key) => {
    if (key === "2") {
      localStorage.setItem("authToken", null);
      localStorage.setItem("loggedInUserType", null);
      navigate("/");
    }
  };
  const menu = (
    <Menu onClick={({ key }) => handleHeaderClick(key)}>
      <Menu.Item key="2" icon={<LogoutOutlined />}>
        LogOut
      </Menu.Item>
    </Menu>
  );
  const [isBackTopVisible, setIsBackTopVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setIsBackTopVisible(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleMenuClick = (item) => {
    if (item.key === "dashboard") {
      navigate("/dashboard");
    }

    if (item.key === "fund") {
      navigate("/fundManagementPage");
    }
    if (item.key === "expenses") {
      navigate("/expenses");
    }
    if (item.key === "users") {
      navigate("/users");
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout>
        <Header className="shadow-md"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0",
            background:"#fdfdfd",
          }}
        >
          <div className="flex items-center w-[250px] justify-center">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",

                gap: "5px",
              }}
            >
              <PiCross
                style={{
                  width: "30px",
                  height: "65px",
                  color: "001529",
                }}
              />
              <span
                className="text-[20px] font-bold  text-[#001529] uppercase"
                style={{
                  textTransform: "uppercase",
                }}
              >
                Zion Church
              </span>
            </div>
            {/* <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 34,
                height: 64,
                color: "white",
              }}
            /> */}
          </div>

          <div className="demo-logo" />

          {/* <div
            style={{
              flex: 1,
              minWidth: 0,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {headerIteam.map((item) => (
              <Button
                key={item.key}
                type="text"
                icon={item.icon}
                style={{ color: "white" }}
                onClick={() => handleHeaderClick(item.key)}
              >
                {item.text}
              </Button>
            ))}
          </div> */}

          <div className="flex items-center">
            <Dropdown overlay={menu} trigger={["hover"]}>
              <Button
                className="uppercase"
                type="text"
                icon={<UserSwitchOutlined />}
                style={{ color: "001529" }}
              >
                User: {loggedInUserType}
              </Button>
            </Dropdown>
            <Button
              type="text"
              icon={collapsed ? <RiMenu2Line /> : <RiMenu3Line />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 34,
                height: 64,
                color: "001529",
              }}
            />
          </div>
        </Header>
        <Layout>
          <Sider
            collapsible={false}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            width={250}
          >
            <Menu
              theme="dark"
              defaultSelectedKeys={["dashboard"]}
              mode="inline"
              items={
                userType === "admin"
                  ? adminUserItems
                  : userType === "userFund"
                  ? UserFundItems
                  : userType === "userExpences"
                  ? UserExpencesItems
                  : [] // Empty array as default or fallback
              }
              onClick={handleMenuClick}
              style={{
                position: "sticky",
                marginTop: "10px",
                padding: "6px",
                height: "100%",
              }}
            />
          </Sider>
          <Content
            style={{
              margin: "10px 10px",
            }}
          >
            <div
              style={{
                // padding: 24,
                minHeight: 360,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: borderRadiusLG,
              }}
            >
              {isBackTopVisible && (
                <FloatButton.Group
                  shape="circle"
                  style={{
                    right: 24,
                  }}
                >
                  <FloatButton.BackTop visibilityHeight={0} />
                </FloatButton.Group>
              )}
              {children}
            </div>
          </Content>
        </Layout>

        {/* <Footer
          style={{
            textAlign: "center",
          }}
        ></Footer> */}
      </Layout>
    </Layout>
  );
};
export default App;
