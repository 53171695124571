import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Modal,
  Table,
  Typography,
  Form,
  Popconfirm,
  Space,
} from "antd";
import { EditOutlined, DeleteOutlined ,  PlusOutlined,} from "@ant-design/icons";
import { AiOutlineUserAdd } from "react-icons/ai";
import { HiOutlineUsers } from "react-icons/hi2";
import axios from "axios";
import Swal from "sweetalert2";
import LayoutNew from "../Layout";
import UserForm from "./UserForm";

const { Title } = Typography;

export default function UserMain() {
  const [loggedInUserType, setLoggedInUserType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const userType = localStorage.getItem("loggedInUserType");
    if (userType) {
      setLoggedInUserType(userType);
    }
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/users`
      );
      setUsers(response.data.data); // Adjust this according to your actual response structure
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const showModal = (user = null) => {
    setCurrentUser(user);
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (currentUser) {
          updateUser(currentUser._id, values);
        } else {
          createUser(values);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentUser(null);
  };

  const createUser = async (userData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/users/register`,
        userData
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "User Created Successfully!",
          showConfirmButton: false,
          timer: 1000,
        });
        setIsModalVisible(false);
        fetchUsers(); // Refresh users list
      }
    } catch (error) {
      console.error("User creation failed:", error);
      Swal.fire({
        icon: "error",
        title: "User Creation Failed!",
        text:
          error.response?.data?.message ||
          "An error occurred while creating the user.",
      });
    }
  };

  const updateUser = async (userId, userData) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${userId}`,
        userData
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "User Updated Successfully!",
          showConfirmButton: false,
          timer: 1000,
        });
        setIsModalVisible(false);
        setCurrentUser(null);
        fetchUsers(); // Refresh users list
      }
    } catch (error) {
      console.error("User update failed:", error);
      Swal.fire({
        icon: "error",
        title: "User Update Failed!",
        text:
          error.response?.data?.message ||
          "An error occurred while updating the user.",
      });
    }
  };

  const deleteUser = async (userId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${userId}`
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "User Deleted Successfully!",
          showConfirmButton: false,
          timer: 1000,
        });
        fetchUsers(); // Refresh users list
      }
    } catch (error) {
      console.error("User deletion failed:", error);
      Swal.fire({
        icon: "error",
        title: "User Deletion Failed!",
        text:
          error.response?.data?.message ||
          "An error occurred while deleting the user.",
      });
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{index + 1}</span>, // Add 1 to index to start from 1 instead of 0
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          {/* <Button type="link" onClick={() => showModal(record)}>
            Edit
          </Button> */}
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => showModal(record)}
          ></Button>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => deleteUser(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" icon={<DeleteOutlined />} danger></Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <LayoutNew userType={loggedInUserType}>
      <div
        className="flex flex-col items-center justify-between w-full md:flex-row"
        style={{
          background: "#8eb1e1",
          color: "white",
          padding: "12px",
          borderRadius: "8px",
          marginBottom: "24px", // Adjust margin as needed
        }}
      >
        <div className="flex items-center gap-1">
          <HiOutlineUsers style={{ fontSize: "24px", marginRight: "8px", color: "001529"}} />
          <Title
            level={2}
            style={{ fontSize: "24px", marginTop: "8px", color: "001529" }}
          >
            User Management
          </Title>
        </div>

        <Button type="primary" onClick={() => showModal()} className="flex items-center gap-1 ">
          <PlusOutlined style={{ fontSize: "16px" }} />
          Create User
        </Button>
      </div>
      <Layout style={{ paddingTop: "0px",overflowX: "auto"  }}>
        {/* <Button type="primary" onClick={() => showModal()}>
          Add User
        </Button> */}
        <Table
          columns={columns}
          dataSource={users}
          rowKey="_id"
          style={{ minWidth: "600px" }}
          
        //   style={{ marginTop: "16px" }}
        />
        <Modal
          title={currentUser ? "Edit User" : "Add User"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <UserForm form={form} />
        </Modal>
      </Layout>
    </LayoutNew>
  );
}
