// UserForm.js
import React from 'react';
import { Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const UserForm = ({ form, onFinish }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        label="Username"
        rules={[{ required: true, message: 'Please input the username!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: 'Please input the email!' }]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please input the password!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="userType"
        label="User Type"
        rules={[{ required: true, message: 'Please select the user type!' }]}
      >
        <Select placeholder="Select user type">
          <Option value="admin">Admin</Option>
          <Option value="userFund">User Fund</Option>
          <Option value="userExpences">User Expenses</Option>
        </Select>
      </Form.Item>
      {/* <Form.Item>
        <Button type="primary" htmlType="submit">
          Create User
        </Button>
      </Form.Item> */}
    </Form>
  );
};

export default UserForm;
