import React, { useState } from "react";
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  Box,
  Paper,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import zionlogo from "../../src/asset/logo.jpeg"; 

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset previous errors
    setUsernameError("");
    setPasswordError("");

    // Validate username
    if (!username.trim()) {
      setUsernameError("Username is required");
      return;
    }

    // Validate password
    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/users/login`,
        { username, password }
      );
      if (response.data.success) {
        const authToken = response.data.token;
        const loggedInUser = response.data.data;
        localStorage.setItem("authToken", authToken);
        localStorage.setItem("loggedInUserType", loggedInUser.userType);
        localStorage.setItem("userId", loggedInUser._id);
        navigate("/dashboard");
      } else {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: response.data.message || "An error occurred during login.",
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text:
          error.response?.data?.message || "An error occurred during login.",
      });
    }
  };

  const styles = {
    body: {
      // backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundColor: "#8eb1e1",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
      maxWidth: "400px",
      width: "100%",
    },
    avatar: {
      backgroundColor: "#1976D2", // Material-UI primary color
    },
    title: {
      fontWeight: "bold",
      fontSize: "24px",
      marginBottom: "16px",
      textAlign: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    submit: {
      margin: "24px 0 16px",
    },
    link: {
      textAlign: "center",
    },
    errorText: {
      color: "red",
      fontSize: "12px",
    },
    logoContainer: {
      textAlign: "center",
      marginBottom: "16px",
    },
    logo: {
      width: "120px",
      height: "100px",
    },
  };

  return (
    <div style={styles.body}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} style={styles.paper}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {/* <Avatar style={styles.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <div style={styles.logoContainer}>
              <img src={zionlogo} alt="logo" style={styles.logo} />
            </div>

            <Typography component="h1" variant="h5" style={styles.title}>
             ZION <span className="text-gray-700">LOGIN</span> 
            </Typography>
            <form style={styles.form} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={!!usernameError}
                helperText={usernameError}
              />
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={styles.submit}
              >
                Sign In
              </Button>
              {/* <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Link
                    component={RouterLink}
                    to="/forgotpassword"
                    variant="body2"
                    style={styles.link}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="body2"
                    style={styles.link}
                  >
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid> */}
            </form>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
